<template>
    <div ref="noteList">
        <template v-if="loading">
            <LoadingSpinner class="col-12" />
        </template>
        <template v-else-if="Object.keys(items).length == 0">
            <div class="box">
                <span class="text-center">
                    <i>{{ $t('global.no_result') }}</i>
                </span>
            </div>
        </template>
        <template v-else>
            <template v-for="(partants, state) in items">
                <h3 :key="'partant_status_'+state" class="mt-4">{{ $t(`perfs.states.${state}`) }}</h3>
    
                <div v-for="partant in partants" :key="partant.actes_id">
                    <div class="box mt-2" :key="partant.actes_id">
                        <div class="row d-flex">
                            <div class="col-10">
                                <h4 class="mb-0">
                                    <router-link :to="{ name: 'horseFiche', params: { horse_id: partant.horse_id }}">{{ partant.horse_nom | multiCapitalize }}</router-link>
                                </h4>
                                {{ partant.actes_hour }} - {{ partant.hipo_name }}
                            </div>
                            <div class="col-2 text-right">
                                <font-awesome-icon class="h4" :icon="['fad', 'arrow-circle-down']" :flip="partant.full_display ? 'horizontal' : 'vertical'" @click="partant.full_display = !partant.full_display" />
                            </div>
                        </div>
                        <transition name="slide-fade">
                            <span v-if="partant.full_display">
                                <hr>
                                {{ $t('horse.entraineur') }}: {{ partant.trainer_name }}<br>
                                {{ partant.race_name }}
                            </span>
                        </transition>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>


<script type="text/javascript">
    import ActesMixin from "@/mixins/Actes.js"
    import HorseMixin from "@/mixins/Horse.js"
    import _groupBy from 'lodash/groupBy'

	export default {
		name: "ActPerfs",
        mixins: [ActesMixin, HorseMixin],
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        },
        props: {
            horse_id: { type: Number, default: 0 }
        },
        data() {
            return {
                items: [],
                loading: true
            }
        },
        created() {
            this.preinit_component()
        },
        methods: {
            async preinit_component() {
                let temp = []
                
                if(this.horse_id > 0) {
                    temp = await this.getHorseDepart(this.horse_id)
                }
                else {
                    temp = await this.getPartants()
                }

                temp = temp.map(h => ({ ...h, full_display: false }))
                this.items = _groupBy(temp, 'actes_state')
                this.loading = false
            }
        }
	}
</script>
