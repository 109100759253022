import Constants from 'Constants'
import Common from 'GroomyRoot/assets/js/common.js'

var ActesMixin = {
    data() {
        return {
            prev_planif_date: '', /* La date de planification (si un seul acte est sélectionné) */
        }
    },
	methods: {
        /* Méthode générique gérant le cache */
        /* force, passé à true, permet de forcer le reload des données */
        getData: async function(caller, url, type, id, force=false) {
            const result = await this.$request.request_get_api("ActesMixin::getData::"+caller, url)
                .catch(e => {
                    return false
                })

            if(!result.retour) {
                return null
            }

            // this.setCache(type, id, result.retour)
            return result.retour
		},

		/* Retourne la liste des actes, avec leur age par rapport à la date actuelle */
		getActes: async function(start_date, end_date) {
			const params = { 
				'start': start_date,
				'end': end_date
		 	}
            const url = this.constructRoute(Constants.ACTES_URL, params)+"&licence_key="+Constants.USER_LICENCE_KEY

			let result = null
			try {
				result = await this.$request.request_get_api("ActesMixin::getActes", url)
			}
			catch(error) {
				console.error("ActesMixin::getActes => ERROR", error)
				return null
			}

			if(result.retour) return result.retour
			return null
		},
		
		getActesHistory: async function(start_date, end_date) {
			const params = { 
				'start': start_date,
				'end': end_date
		 	}
			const url = this.constructRoute(Constants.ACTES_HIST_URL, params)+"&licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ActesMixin::getActesHistory", url)
			.catch(e => {
                console.error("ActesMixin::getActesHistory => ", e)
                return null
			})
            
            if(result) return result.retour
            return null
        },

        getActesFromHorse: async function(horse_id, force=false) {
            const params = { 'horse_id': horse_id }
            const url = this.constructRoute(Constants.HORSE_ACTES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.getData("getActesFromHorse", url, 'horse_actes', horse_id, force)
        },

        // Données statiques - horse_actes_groupe
        getActesGroupes: function(gamme_filter=false) {
            // On les récupére du localstorage
            let groupes = this.getConfig('acte_groupes')

            // Si on veut filtrer les groupes par rapport à la gamme de licence
            if(gamme_filter) {
                // Si la licence n'a pas la gamme de repro
                if(!this.$store.state.userAccess.hasBreedingAccess) {
                    const to_del = groupes.find(groupe => groupe.actesgroupe_code == 'REPRO')

                    // Si on a la repro en config
                    if(to_del) {
                        const index = groupes.indexOf(to_del)
                        groupes.splice(index, 1)
                    }
                }
			}

            return groupes
        },

        // Filtre les groupes affichables, en fonction de la gamme de licence
        filterActesGroupes: function(groupes) {
            let temp = [...groupes]
            const user_access = this.$store.state.userAccess
            
            // Si pas de repro
            if(!user_access.hasBreedingAccess) {
                const obj_to_del = temp.find(groupe => groupe.actesgroupe_code == 'REPRO')
                const index_to_del = temp.indexOf(obj_to_del)
                temp.splice(index_to_del, 1)
            }

            return temp
        },

        // Données statiques - horse_actes_type
        getActesTypes: async function() {
            const url = Constants.ACTES_TYPES_USER_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ActesMixin::getActesTypes", url)
            .catch(error => {
                console.error("ActesMixin::getActesTypes => ERROR", error)
                return null
            })

            if(result.retour) return result.retour
            return null
    	},

        // Actes type avec détails (groupe lié)
        getActesTypesDetails: async function() {
            const url = Constants.ACTES_TYPES_USER_DET_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ActesMixin::getActesTypesDetails", url)
            .catch(error => {
                console.error("ActesMixin::getActesTypesDetails => ERROR", error)
                return null
            })

            if(result.retour) return result.retour
            return null
        },

        // On va rajouter des infos calculées côté js
		formatActeDate: function(actes) {
			let date
            actes.forEach(acte => {
				if(acte.actes_date != undefined) {
					if(acte.actes_date.length > 10) {
						acte.actes_date = acte.actes_date.substr(0, 10)
					}

					date = new Date(acte.actes_date)
                    acte['actes_date_obj'] = date
					acte["actes_date_year"] = date.getFullYear()
					acte["actes_date_month"] = date.getMonth()+1
					acte["actes_date_day"] = date.getDate()
				}
				else {
					acte["actes_date_year"] = 0
					acte["actes_date_month"] = 0
					acte["actes_date_day"] = 0
				}

				acte["isActive"] = false
			})

			return actes;
		},

        formatActesData: function(items) {
            items.forEach(item => {
                item.class = ""

                if(item.actes_actesstatut === 0) {
                    if(item.actes_ageday < 0) {
                        item.class = "retard"
                    }
                }

                item.id = item.actes_id
                item.isActive = false
            })

            return items
		},
		
		notifyClient: async function(acte_ids) {
			const params = {
				acte_ids: acte_ids
			}
			const url = Constants.ACTES_NOTIFY_CLIENT+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ActesMixin::notify_client", url, params, false)
            .catch(error => {
                console.error("ActesMixin::notify_client => ERROR", error)
                return null
            })
		},

        getPartants: async function() {
            const url = Constants.ACTES_PERFS_PARTANTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("ActesMixin::getPartants", url)
                .then(res => {
                    return res.retour
                })
                .catch(e => {
                    console.error("ActesMixin::getPartants => ERROR", e)
                    return null
                })
        },

        getResultats: async function() {
            const url = Constants.ACTES_PERFS_RESULTATS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("ActesMixin::getResultats", url)
                .then(res => {
                    return res.retour
                })
                .catch(e => {
                    console.error("ActesMixin::getResultats => ERROR", e)
                    return null
                })
        }
	}
}

export default ActesMixin
